import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePageStyle.css';


function HomePage({ brideName, groomName, backgroundImage }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${groomName}&${brideName}/upload`);
  };

  return (
    <div 
      className="home-container" 
      style={{ backgroundImage: `url(${backgroundImage})` }} 
      data-aos="fade-zoom-in" 
      data-aos-offset="150" 
      data-aos-easing="ease-in-sine" 
      data-aos-duration="3000"
    >
      <p className="bride-groom-names">
        {`${groomName}`}<br/>&<br/>{`${brideName}`}
      </p>
      <button className="start-button" onClick={handleClick}>
        <span>Start</span>
      </button>
    </div>
  );
}

export default HomePage;
