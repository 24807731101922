import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './LanguageButton.css'; 

function LanguageButton({ setLocale }) {
  const [isOpen, setIsOpen] = useState(false); 
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (lang) => {
    setLocale(lang);
    setIsOpen(false);
  };

  return (
    <div dir='auto' className="language-button-wrapper">
      <button className="language-toggle-button"  onClick={toggleDropdown}>
        🌐 <FormattedMessage id="language" defaultMessage="Language" />
      </button>
      {isOpen && (
        <ul className="language-dropdown">
          <li onClick={() => selectLanguage('en')}>English</li>
          <li onClick={() => selectLanguage('he')}>עברית</li>
          <li onClick={() => selectLanguage('ar')}>العربية</li>
        </ul>
      )}
    </div>
  );
}

export default LanguageButton;
