import React from 'react';
import { FormattedMessage } from 'react-intl';
import './BusinessSummaryStyle.css';

function BusinessSummary() {
  return (
    <div dir='auto' className="business-summary-container" data-aos="fade-up">
      <h2><FormattedMessage id="aboutTitle" /></h2>
      <p><FormattedMessage id="aboutDescription" /></p>
      <div dir='auto'>
      <ul>
        <li>1.<FormattedMessage id="feature1"  /></li>
        <li>2.<FormattedMessage id="feature2"  /></li>
        <li>3.<FormattedMessage id="feature3"  /></li>
      </ul>
      </div>
    </div>
  );
}

export default BusinessSummary;
