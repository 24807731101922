import React from 'react';
import { FormattedMessage } from 'react-intl';
import './PricingSectionStyle.css';

function PricingSection() {
  return (
    <div dir='auto' className="pricing-section-container" data-aos="fade-up">
      <h2><FormattedMessage id="pricingTitle" /></h2>
      <div className="pricing-cards">
        <div className="pricing-card">
          <h3><FormattedMessage id="pricingBasic" /></h3>
          <p>800₪</p>
          <p><FormattedMessage id="basicFeatureA"/></p>
          <p><FormattedMessage id="basicFeatureB"/></p>
        </div>
        <div className="pricing-card">
          <h3><FormattedMessage id="pricingStandard" /></h3>
          <p>1000₪</p>
          <p><FormattedMessage id="standardFeatureA" /></p>
          <p><FormattedMessage id="standardFeatureB" /></p>
        </div>
      </div>
    </div>
  );
}

export default PricingSection;
