import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import HeroSection from "../../components/HeroSection/HeroSection";
import PricingSection from "../../components/PricingSection/PricingSection";
import BusinessSummary from "../../components/BusinessSummary/BusinessSummary";
import GallerySection from "../../components/GallerySection/GallerySection";
import LanguageButton from "../../components/LanguageButton/LanguageButton";  

import messages_en from '../../translations/en.json';
import messages_he from '../../translations/he.json';
import messages_ar from '../../translations/ar.json';

const messages = {
  en: messages_en,
  he: messages_he,
  ar: messages_ar,
};

function LandingPage() {
  const [locale, setLocale] = useState('he'); 

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div style={{ backgroundColor: "#927878" }} data-aos="fade-zoom-in" 
           data-aos-offset="150" data-aos-easing="ease-in-sine" data-aos-duration="3000"> 
        <LanguageButton setLocale={setLocale} />  
        <HeroSection />
        <BusinessSummary />
        <GallerySection />
        <PricingSection />
      </div>
    </IntlProvider>
  );
}

export default LandingPage;
