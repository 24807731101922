import React from 'react';
import { FormattedMessage } from 'react-intl';
import './HeroSectionStyle.css';

function HeroSection() {
  return (
    <div dir='auto' className="hero-container" data-aos="fade-up">
      <h1><FormattedMessage id="heroTitle" /></h1>
      <p><FormattedMessage id="heroDescription"  /></p>
    </div>
  );
}

export default HeroSection;
