import React from 'react';
import {  FormattedMessage } from 'react-intl'; // Import react-intl
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from 'swiper/modules';  
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow'; 
import './GallerySectionStyle.css'; 
import image1 from '../../images/Gallery/1.jpeg';
import image2 from '../../images/Gallery/2.jpeg';
import image3 from '../../images/Gallery/3.jpeg';
import image4 from '../../images/Gallery/4.jpeg';
import image5 from '../../images/Gallery/5.jpeg';
import image6 from '../../images/Gallery/6.jpeg';
import image7 from '../../images/Gallery/7.jpeg';
import image8 from '../../images/Gallery/8.jpeg';
import image9 from '../../images/Gallery/9.jpeg';
import image10 from '../../images/Gallery/10.jpeg';

function GallerySection() {

  const galleryImages = [
    image1, image2, image3, image4, image5,
    image6, image7, image8, image9, image10
  ];

  return (
    <div dir='auto' className="gallery-container" data-aos="fade-up">
      <div className="gallery-heading">
       
        <h1>
          <FormattedMessage id="galleryTitle"  />
        </h1>
        <p>
          <FormattedMessage id="galleryDescription" />
        </p>
      </div>
      <div dir='auto'>
      <Swiper
        grabCursor
        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        
        centeredSlides={true}
        slidesPerView="auto"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        spaceBetween={5}
        modules={[EffectCoverflow, Autoplay]}
      >
        {galleryImages.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{ backgroundImage: `url(${image})` }}
            className="swiper-slide-custom"
          />
        ))}
      </Swiper>
      </div>
    </div>
  );
}

export default GallerySection;
