import React, { useState } from 'react';
import './UploadPageStyle.css';
import axios from 'axios';

function UploadPage({ backgroundImage }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadFlag, setUploadFlag] = useState(false);
  const MAX_FILES = 10;

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + selectedFiles.length > MAX_FILES) {
      alert(`You can only upload a maximum of ${MAX_FILES} files.`);
      return;
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFiles.length === 0) {
      setUploadFlag(false);
      console.log('No files selected');
      return;
    } else {
      setUploadFlag(true);
    }

    try {
      // Request pre-signed URLs
      const fileInfos = selectedFiles.map(file => ({
        fileName: `${Date.now()}_${file.name}`,
        fileType: file.type
      }));

      const presignedResponse = await axios.post('https://moment-share-35b5803454f8.herokuapp.com/generate-presigned-urls', {
        files: fileInfos
      });

      const presignedUrls = presignedResponse.data.urls;

      // Upload files to S3 using pre-signed URLs
      const uploadPromises = presignedUrls.map(({ fileName, url }, index) => {
        const file = selectedFiles[index];
        return axios.put(url, file, {
          headers: {
            'Content-Type': file.type
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(prevProgress => (prevProgress + progress / selectedFiles.length));
              console.log('Upload progress:', progress);
            }
          }
        });
      });

      await Promise.all(uploadPromises);

      console.log('Upload successful!');
      setUploadProgress(0);
      setSelectedFiles([]);
      setUploadFlag(false);
      alert('Upload successful!');
    } catch (error) {
      console.error('Error uploading files:', error.response ? error.response.data : error.message);
      alert('Error uploading files. Please try again.');
    }
  };

  return (
    <div
      className="home-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      data-aos="fade-zoom-in"
      data-aos-offset="150"
      data-aos-easing="ease-in-sine"
      data-aos-duration="3000"
    >
      <form className="upload-form" onSubmit={handleSubmit}>
        <p className="upload-text">
        اهلا وسهلا فيكن/م <br />
          ,زدتوا لفرحنا فرح<br />
          هون بنفع تنزلوا الصور الي صورتوها 
          لحتى يضلوا محفوظات عنا 
          شكرا لمشاركتكن/م فرحنا <br/>💛<br />
        </p>
        <label className="custom-file-upload">
          <input
            type="file"
            multiple={selectedFiles.length < MAX_FILES}
            onChange={handleUpload}
          />
          choose file
        </label>
        {uploadFlag && (
          <div className="progress-bar">
            <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
          </div>
        )}
        <div className='buttons-container'>
          <button type="submit" className="upload-button">Send</button>
        </div>
      </form>
    </div>
  );
}

export default UploadPage;
