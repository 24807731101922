// import  backgroundImage from '../src/images/background.jpeg'
import  backgroundImage2 from '../src/images/aseel&ehab.jpeg'

const weddings = [
{
    brideName: "Aseel",
    groomName : "Ehab",
    backgroundImage : backgroundImage2
}
]


export default  weddings;