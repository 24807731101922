import React from 'react';
import { FaInstagram } from 'react-icons/fa';
// import { RiTiktokFill } from 'react-icons/ri';
import './FooterStyle.css';

function Footer() {
  const year = new Date().getFullYear();
  
  return (
    <footer>
      <ul>
        {/* <li>
          <a href="https://www.tiktok.com">
            <RiTiktokFill className="footer-icon" />
          </a>
        </li> */}
        <li>
          <a href="https://www.instagram.com/moment_share2024">
            <FaInstagram className="footer-icon" />
          </a>
        </li>
      </ul>
      <p>Copyright ⓒ {year} MomentShare</p>
    </footer>
  );
}

export default Footer;
